import Vue from "vue";

// main.js includes global styles and utilities
import "@/base/global-js/main.js";

// Component dependencies

// base
import CloButton from "@/base/CloButton/CloButton";
Vue.component("CloButton", CloButton);

import CloDialog from "@/base/CloDialog/CloDialog";
Vue.component("CloDialog", CloDialog);

import CloDivider from "@/base/CloDivider/CloDivider";
Vue.component("CloDivider", CloDivider);

import CloHeading from "@/base/CloHeading/CloHeading";
Vue.component("CloHeading", CloHeading);

import CloPage from "@/base/CloPage/CloPage";
Vue.component("CloPage", CloPage);

import CloSkipTo from "@/base/CloSkipTo/CloSkipTo";
Vue.component("CloSkipTo", CloSkipTo);

import CloLogoLabShort from "@/logos/CloLogoLabShort/CloLogoLabShort";
Vue.component("CloLogoLabShort", CloLogoLabShort);

import CloLogoLab from "@/logos/CloLogoLab/CloLogoLab";
Vue.component("CloLogoLab", CloLogoLab);

import GlobalStyles from "./globalStyles.vue";
Vue.component("GlobalStyles", GlobalStyles);

import CloInlineList from "@/base/CloInlineList/CloInlineList";
Vue.component("CloInlineList", CloInlineList);

import CloUnorderedList from "@/base/CloUnorderedList/CloUnorderedList";
Vue.component("CloUnorderedList", CloUnorderedList);

// forms
import CloSuggest from "@/forms/CloSuggest/CloSuggest";
Vue.component("CloSuggest", CloSuggest);

import CloInputCheckbox from "@/forms/CloInputCheckbox/CloInputCheckbox";
Vue.component("CloInputCheckbox", CloInputCheckbox);

import CloInputText from "@/forms/CloInputText/CloInputText";
Vue.component("CloInputText", CloInputText);

import CloInputSelect from "@/forms/CloInputSelect/CloInputSelect";
Vue.component("CloInputSelect", CloInputSelect);

// grid
import CloGridFlex from "@/grid/CloGridFlex/CloGridFlex";
Vue.component("CloGridFlex", CloGridFlex);

// icons
import CloIcon from "@/icons/CloIcon/CloIcon";
Vue.component("CloIcon", CloIcon);

// layouts
import CloMessage from "@/layouts/CloMessage/CloMessage";
Vue.component("CloMessage", CloMessage);

// navigation
import CloHeader from "@/navigation/CloHeader/CloHeader";
Vue.component("CloHeader", CloHeader);

// deprecated
import CloHeaderBeta from "@/deprecated/CloHeaderBeta";
Vue.component("CloHeaderBeta", CloHeaderBeta);

import username from './username.js'

// internationalization
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: "locale",
    fallbackLocale: "locale",
    messages: {
        locale: window.langMap,
    },
});

// initialize components on page load
window.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector("[data-lichen-vue]")) {
        var els = document.querySelectorAll("[data-lichen-vue]");
        Array.prototype.forEach.call(els, function (el) {
            new Vue({ el: el, i18n });
        });
    }

    // initialize modals
	if (document.querySelector('[data-lichen-dialog]')) {
		const els = document.querySelectorAll('[data-lichen-dialog]');
		Array.prototype.forEach.call(els, function (el) {
			const id = el.getAttribute('data-lichen-dialog');
			new Vue({
				el: el,
				i18n,
				data() {
					return {
						open: false,
						opener: null,
						id: id,
						confirmMessage: ''
					};
				},
				mounted() {
					const togglers = document.querySelectorAll('[data-lichen-dialog-toggle="' + this.id + '"]');
					Array.prototype.forEach.call(togglers, (toggler) => {
						toggler.addEventListener('click', this.show);
					});
					const newAccountForm = document.getElementById('userForm')
					if (newAccountForm && id === 'modal-email-confirm') {
						const dataToConfirm = newAccountForm.getAttribute('data-confirm');
						if (dataToConfirm) {
							newAccountForm.addEventListener('submit', (e) => {
								if (e.submitter.id === 'form-submit') {
									e.preventDefault();
									const input = document.getElementById(dataToConfirm.slice(1))
									this.confirmMessage = input.value;
									this.open = true;
									this.opener = input;
								} else {
									//e.target.submit();
								}
							});
						}
					}
				},
				methods: {
					show(e) {
						e.preventDefault();
						this.open = true;
						this.opener = e.target;
					},
					close() {
						this.open = false;
						// important: put focus back on element that triggered the dialog
						if (this.opener) {
							this.opener.focus();
						}
					},
				},
			});
		});
	}
});