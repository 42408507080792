import axios from 'axios'

window.addEventListener("DOMContentLoaded", checkUsername)

function checkUsername() {
	const form = document.getElementById('userForm');
	const input = document.getElementById('input-user-name');
	if (form && input) {
		input.addEventListener('blur', getAvailableUsernames)
	}
}

async function getAvailableUsernames() {
	const userName = document.getElementById('input-user-name');
	const firstName = document.getElementById('input-first-name');
	const lastName = document.getElementById('input-last-name');
	const url = document.querySelector('[data-username-suggestions-url]').getAttribute('data-username-suggestions-url')
	const { data } = await axios.get(url + userName.value, {
		params: { firstName: firstName.value, lastName: lastName.value }
	})
	const unavailable = document.getElementById('username-unavailable')
	unavailable.setAttribute('aria-hidden', !data.length)
	if (data.length) {
		showSuggestions(data)
	}
}

function showSuggestions(suggestions) {
	const input = document.getElementById('input-user-name');
	input.setAttribute('aria-describedby', 'username-suggestions');
	const list = document.getElementById('suggestion-list');
	const buttons = list.querySelectorAll('button')
	Array.prototype.forEach.call(buttons, (button) => {
		button.removeEventListener('click', chooseName)
	})
	list.innerHTML = ''
	suggestions.forEach(name => {
		const button = document.createElement('button')
		button.setAttribute('type', 'button')
		button.textContent = name
		const li = document.createElement('li')
		li.append(button)
		list.append(li)
		button.addEventListener('click', chooseName)
	})
}

function chooseName(e) {
	const name = e.target.textContent
	const input = document.getElementById('input-user-name');
	input.value = name
}

